import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

function UrunDetay() {
  let params = useParams();
  let navigate = useNavigate()
  const [urun, setUrun] = useState(null);
  useEffect(() => {
    axios.get(`/urun/${params.id}`).then((res) => {
      console.log(res);
      setUrun(res.data);
    });
  }, []);

  function update(e) {
    e.preventDefault();
  }

  function sil(e,id){
    if(window.confirm("Bu ürünü silmek istediğiniz emin misiniz")){
      e.target.innerHTML = "<i class='fa-solid fa-spin fa-spinner'></i>"
      axios.post(`/delete/urunler/urun_id/${id}`).then(res=>{
        console.log(res)
        if(res.data == "ok"){
          navigate("/urunler")
          
        }
      })
    }
  }

  return (
    <div className="screen">
      <div className="page">
        {urun ? (
          <div className="row g-2">
            <div className="col-6">
              <div className="bg-white shadow rounded p-2 kutu">
                <div className="text-center text-lg font-bold mb-2">{urun[0].urun_isim}</div>

                <img
                  className="w-full"
                  src={
                    urun[0].urun_resim != ""
                      ? urun[0].urun_resim
                      : axios.defaults.base + "assets/img/yemek.jpg"
                  }
                />
                

                <a className="w-100 d-none mt-1 text-center bg-green-500 py-2 font-medium rounded" href={axios.defaults.baseURL+"resim/"+params.id+"/?type=urun"}><i className="fa-solid fa-image"></i> Resim Yükle</a>
                
                <iframe id="ifr" src={`${axios.defaults.baseURL+"resim/"+params.id+"/?type=urun"}`} height="50"></iframe>
                <div className="font-bold text-lg">Kalan Stok: <span className="text-primary">{urun[0].stok}</span></div>

              </div>
            </div>

            <div className="col-6">
              <div className="bg-white shadow rounded p-2 kutu">
                <form onSubmit={(e) => update(e)}>
                  <div className="row g-2">
                    <div className="col-12">
                      <div className="font-medium text-sm">Ürün İsmi</div>
                      <input
                        defaultValue={urun[0].urun_isim}
                        type="text"
                        className="font-medium px-2 py-1 bg-slate-100 rounded w-100"
                      />
                    </div>

                    <div className="col-12">
                      <div className="font-medium text-sm">Barkod</div>
                      <input
                        defaultValue={urun[0].barkod}
                        type="text"
                        className="font-medium px-2 py-1 bg-slate-100 rounded w-100"
                      />
                    </div>

                    <div className="col-6">
                        <div className="font-medium text-sm">
                            Fiyat
                        </div>

                        <input
                        defaultValue={urun[0].urun_fiyat}
                        type="text"
                        className="font-medium px-2 py-1 bg-slate-100 rounded w-100"
                      />
                    </div>

                    
                    <div className="col-6">
                        <div className="font-medium text-sm">
                            Kalori
                        </div>

                        <input
                        defaultValue={urun[0].kalori}
                        type="text"
                        className="font-medium px-2 py-1 bg-slate-100 rounded w-100"
                      />
                    </div>

                    <div className="col-6">
                        <button onClick={e=>sil(e,params.id)} type="button" className="w-100 text-white bg-red-500 py-2 font-medium rounded"><i className="fa-solid fa-trash"></i> Sil</button>
                    </div>

                    
                    <div className="col-6">
                        <button type="submit" className="w-100  bg-green-500 py-2 font-medium rounded"><i className="fa-solid fa-check"></i> Düzenle</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default UrunDetay;
