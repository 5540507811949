import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip"
import IframeResizer from "iframe-resizer-react";
import {aylar} from "../iskonto"
function VeliIndex() {
  const [user, setUser] = useState(null);
  const [urunler, setUrunler] = useState(null);
  const [bakiyeForm, setBakiyeForm] = useState(false);
  const [miktar, setMiktar] = useState(0)
  const [token, setToken] = useState(null)
  const [siparisler, setSiparisler] = useState(null)

  

  useEffect(() => {


    axios.get(`/getUser/${localStorage.veli_id}`).then((res) => {
      console.log(res.data);
      setUser(res.data);
        var _ids = []
        res.data.yemekler.map(val=>_ids.push(val.urun_id))

      axios.get(`/urunler/${res.data[0].to_okul}?extra=${_ids.join(",")}`).then((res2) => {
        setUrunler(res2.data);
        console.log(res2);
      });

      axios.get(`/ogrenciSiparisler/${res.data[0].ogrenci_id}`).then(r2=>{
        console.log(r2)
        setSiparisler(r2.data)
      })
    });
  }, []);



  function setKabul(e, kabul, id) {
    e.stopPropagation();

    
    const c = e.currentTarget
    if (
      !c.classList.contains("opacity-50") 
    ) {
      c.classList.add("opacity-50");
    
      axios
        .post(`/setKabul/${id}`, {
          kabul: c.dataset.kabul == 1 ? 0 : 1,
        })
        .then((res) => {
          c.classList.remove("opacity-50");
          
          if (res.data == 0) {
            c.dataset.kabul = 0;
            c.querySelector(".izin-text").innerText = "İzinsiz";
            c.querySelector("i").classList.add("fa-cancel");
            c.querySelector("i").classList.remove("fa-check");
            c.querySelector(".izin-bg").classList.add("bg-red-200")
            c.querySelector(".izin-bg").classList.add("text-red-700")
            c.querySelector(".izin-bg").classList.remove("bg-green-200")
            c.querySelector(".izin-bg").classList.remove("text-green-700")
          }
          if (res.data == 1) {
            c.dataset.kabul = 1;
            c.querySelector("i").classList.remove("fa-cancel");
            c.querySelector("i").classList.add("fa-check");
            c.querySelector(".izin-text").innerText = "İzinli";
            c.querySelector(".izin-bg").classList.remove("bg-red-200")
            c.querySelector(".izin-bg").classList.remove("text-red-700")
            c.querySelector(".izin-bg").classList.add("bg-green-200")
            c.querySelector(".izin-bg").classList.add("text-green-700")
          }
        });
    }
  }

  function add(e, _miktar){
   setMiktar(parseInt(miktar) + parseInt(_miktar))
  }

  function getFrame(e){
    e.preventDefault();
    const data = {
      amount: e.target[0].value,
      isim: e.target[1].value,
      eposta: e.target[2].value,
      tel: e.target[3].value,
      adres: e.target[4].value,
      ogrenci: localStorage.veli_id
    }


    e.target[5].innerHTML = "<i class='fa-spin fa-spinner fa-solid'></i>"
    e.target[5].setAttribute("disabled","")

    console.log(data)
    axios.post(`/odeme`,data).then(res=>{
      console.log(res)
      if("status" in res.data){
        if(res.data.status == "success"){
          console.log(res.data.token)
          setToken(res.data.token)
        }else{
          alert(res.data.reason)
          window.location.reload()
        }
      }
    })
  }
  return (
    <div className="screen">
          
      <div className="page">


    {token ?
    <>
    <IframeResizer
  log
  src={`https://www.paytr.com/odeme/guvenli/${token}`} 
  style={{ width: '1px', minWidth: '100%',marginBottom:20,background:"#fff"}}
/>

    </> 
: ""}

        <div className="row g-2">
          <div className="col-12">
            <div className="kutu shadow-sm rounded p-3 bg-white">
              {user && !token ? (
                <div className="row g-2">
                 
                  <div className="col-4">
                    <div className="h-full w-full flex items-center justify-center">
                      <img
                        src={user[0].resim != "" ? user[0].resim : axios.defaults.base+"assets/img/profil.jpg"}
                        className="w-36 h-36 object-cover rounded-full"
                      />
                    </div>
                  </div>
                 
                  <div className="col-7">
                    <div className="h-full w-full  flex-column flex justify-between">
                      <div>
                        <div className="font-medium text-2xl">
                          {user[0].isim}{" "}
                          <span className=" text-primary">
                            {" "}
                            - {user[0].numara}{" "}
                          </span>
                        </div>
                        <div className="font-medium text-slate-400">
                          {user[0].tc}
                        </div>
                      </div>

                      <div className="flex  mt-3">
                        <div>
                          <div className="fiyat-text text-primary lh-1 font-bold text-3xl">
                            {user[0].bakiye} ₺
                          </div>
                          <div className="font-medium text-xl ">
                            Güncel Bakiye
                          </div>

                          <div className=" mt-3">
                          <div className="font-medium text-xs text-slate-400">Günlük Harcama Limit (TL)</div>
                          <input min={0} onChange={e=>{
                            axios.post(`/update/ogrenci/ogrenci_id/${localStorage.veli_id}?str=gunluk_limit = ${e.target.value}`).then(res=>{
                              console.log(res)
                              
                            })
                          }} type="number" defaultValue={user[0].gunluk_limit} placeholder="Günlük Harcama Limit (TL)" className="rounded bg-slate-100 p-1 font-medium text-xs" />                            
                          </div>
                        

                          
                        </div>

                        <div>
                           <ReactTooltip effect="solid" />
                          <button onClick={e=>setBakiyeForm(!bakiyeForm)} data-tip="Bakiye Yükle"  className="ml-3 bg-primary font-bold rounded-full w-12 h-12 text-2xl font-bold text-white">
                            +
                          </button>
                        </div>

                      
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="col-5">
                    <div className="h-full w-full flex items-center justify-center">
                      <div className="w-36 h-36 skeleton object-cover rounded-full"></div>
                    </div>
                  </div>
                  <div className="col-7">
                    <div className="h-full w-full flex-column flex justify-between">
                      <div>
                        <div className="w-48 skeleton "></div>
                        <div className="w-12 ml-3 skeleton "></div>
                        <div className="w-24 skeleton d-block mt-1"></div>
                      </div>

                      <div>
                        <div className="w-24 skeleton d-block mt-1"></div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>


            {user && !token && bakiyeForm ? 

            <div className="kutu shadow-sm rounded p-3 bg-white mt-2">
                <form onSubmit={e=>getFrame(e)}>
                    <div className="font-bold text-lg">Bakiye Yükle</div>
                    <div className="row g-2 mb-3">
                      <div className="col">
                        <div onClick={e=>add(e,20)} className="fiyat-text hover:bg-red-500/75 cursor-pointer p-2 rounded color-red-500 font-bold text lg bg-red-500/50  text-center border-2 border-red-500">
                          20 ₺
                        </div>
                      </div>

                       <div className="col">
                        <div onClick={e=>add(e,30)} className="fiyat-text hover:bg-red-500/75 cursor-pointer p-2 rounded color-red-500 font-bold text lg bg-red-500/50  text-center border-2 border-red-500">
                          30 ₺
                        </div>
                      </div>

                       <div className="col">
                        <div onClick={e=>add(e,50)} className="fiyat-text hover:bg-red-500/75 cursor-pointer p-2 rounded color-red-500 font-bold text lg bg-red-500/50  text-center border-2 border-red-500">
                          50 ₺
                        </div>
                      </div>

                      <div className="col">
                        <div onClick={e=>add(e,100)} className="fiyat-text hover:bg-red-500/75 cursor-pointer p-2 rounded color-red-500 font-bold text lg bg-red-500/50  text-center border-2 border-red-500">
                          100 ₺
                        </div>
                      </div>

                      
                      <div className="col">
                        <div onClick={e=>add(e,200)} className="fiyat-text hover:bg-red-500/75 cursor-pointer p-2 rounded color-red-500 font-bold text lg bg-red-500/50  text-center border-2 border-red-500">
                          200 ₺
                        </div>
                      </div>
                    </div>
                <div className="row g-2">
                  <div className="col-6">
                  <label className="lh-1 font-medium text-primary">Yüklenecek Bakiye (TL)</label>
                    <input value={miktar !=0 ? miktar : ""} onChange={e=>e.target.value == "" ? setMiktar(0) : setMiktar(e.target.value)} placeholder="Yüklenecek Bakiye" type="number" min={0} required className="text-black rounded placeholder-black font-medium p-2 w-full bg-red-500/50" />
                  </div>

                  <div className="col-6">
                    <label className="lh-1 font-medium">İsim Soyisim</label>
                    <input defaultValue={user[0].isim} placeholder="İsim Soyisim" type="text"  required className="bg-slate-100 rounded font-medium p-2 w-full" />
                  </div>

                  <div className="col-6">
                    <label className="lh-1 font-medium">E-Posta</label>
                    <input placeholder="E-Posta" type="email"  required className="bg-slate-100 rounded font-medium p-2 w-full" />
                  </div>

                  <div className="col-6">
                    <label className="lh-1 font-medium">Telefon Numarası</label>
                    <input placeholder="Telefon Numarası" type="tel" defaultValue={user[0].telefon}  required className="bg-slate-100 rounded font-medium p-2 w-full" />
                  </div>

                  <div className="col-6">
                    <label className="lh-1 font-medium">Adres</label>
                    <input placeholder="Adres" type="text"  required className="bg-slate-100 rounded font-medium p-2 w-full" />
                  </div>

                  <div className="col-6">
                    <div>
              <label >&nbsp;</label>
                    <button disabled={miktar == 0 ? true : false} className="button p-2 w-full h-full d-block bg-primary fiyat-text">Ödeme Yap {miktar != 0 ? `(${miktar} ₺)` : ""}</button>

                    </div>
                  </div>
                </div>
               
                </form>
                
            </div>

            : ""
          }
          </div>
        </div>

        {!token ? 

        <div className="row mt-2">
          <div className="col-6 d-none">
            <div className="kutu bg-white p-2 rounded shadow">
              <div className="font-medium text-lg">
                <i className="fa-solid fa-check"></i> Yemek İzinleri
              </div>

              {user == null ? (
                <div>
                  <div className="skeleton w-100 mb-1"></div>
                  <div className="skeleton w-100 mb-1"></div>
                  <div className="skeleton w-100 mb-1"></div>
                  <div className="skeleton w-100 mb-1"></div>
                </div>
              ) : (
                <></>
              )}
              {user &&
                user.yemekler.map((val, i) => {
                  return (
                    <div
                      onClick={(e) => setKabul(e, val.kabul, val.ou_id)}
                      data-kabul={val.kabul}
                      className={
                        val.kabul == 1
                          ? "hover:bg-slate-100 rounded cursor-pointer text-green-600 font-medium text-lg cursor-pointer flex me-auto items-center"
                          : "hover:bg-slate-100 rounded cursor-pointer flex  items-center text-red-700 font-medium text-lg cursor-pointer"
                      }
                      key={i}
                    >
                      {val.urun_isim}{" "}
                      {val.kabul == 0 ? (
                        <span className="ml-auto d-block">
                          <i className="fa-solid fa-cancel "></i>{" "}
                          <span id="now">İzinsiz</span>{" "}
                        </span>
                      ) : (
                        <span className="ml-auto d-block">
                          <i className="fa-solid fa-check "></i>{" "}
                          <span id="now">İzinli</span>
                        </span>
                      )}
                    </div>
                  );
                })}
            </div>
          </div>



          <div className="col-12">
            <div className="row g-2">

          
          {
            user && user.yemekler.map((val,i)=>{
              return(
                <div key={i}  className="col-4">
                <div 
                  onClick={(e) => setKabul(e, val.kabul, val.ou_id)}
                data-kabul={val.kabul} className="cursor-pointer  bg-white rounded shadow border">
                    <div  className={val.kabul == 1 ? "izin-bg bg-green-200 text-green-700 font-medium text-lg p-2" : "izin-bg bg-red-200 text-red-700 font-medium text-lg p-2"}> {val.kabul == 1 ? <i className="fa-solid fa-check"></i> :  <i className="fa-solid fa-cancel"></i>} <span className="izin-text">{val.kabul == 1 ? "İzinli" : "İzinsiz"}</span></div>
                    <img loading="lazy" className="w-full object-contain rounded h-36" src={val.urun_resim != ""? val.urun_resim  :  axios.defaults.base+"assets/img/yemek.jpg"} alt="" />
                    <div className="p-2">
                      <div className="font-medium text-lg">{val.urun_isim}</div>

                      <div className="flex items-center justify-between">
                      <div className="font-medium text-end text-green-600 lh-1 text-lg fiyat-text">{val.urun_fiyat} ₺</div>
                    <div className="font-medium text-sm">{val.kalori} kcal</div>
                      </div>
                    </div>
                </div>
              </div>
              )
            })
          }
  </div>
          </div>
          
        </div> : ""}

        {!token ?
        
        <div className="bg-white kutu mt-2 rounded shadow p-2">
            <div className="lh-1 font-medium text-lg text-center">Öğrencinizin Sipariş Listesi</div>
            <div className="lh-1 font-medium text-sm text-slate-500 text-center">Son 15 sipariş listeleniyor</div>
            <table className=" table mt-2">
              <thead>
                <tr className="text-primary">
                  <th>Ürün</th>
                  <th>Fiyat</th>
                  <th>Tarih</th>
                </tr>
              </thead>
              <tbody>
              {siparisler  && siparisler.map((val,i)=>{
                  return(
                    <tr >
                      <td className="font-medium">{val.urun}</td>
                      <td className="fiyat-text text-green-600 font-medium">{val.fiyat} ₺</td>
                      <td className="font-medium  text-slate-600">{
                       
                          new Date().toDateString() == new Date(val.tarih).toDateString() ? `Bugün , ${new Date(val.tarih).getHours()}:${new Date(val.tarih).getMinutes()}` : `${new Date(val.tarih).getDay()} ${aylar[new Date().getMonth()]}, ${new Date(val.tarih).getHours()}:${new Date(val.tarih).getMinutes()}`
                       
                        }<i className="ms-1 fa-solid fa-clock text-slate-600 text-sm"></i> </td>
              
                    </tr>
                  )
                })}

              </tbody>
            </table>

            
    
        
        </div> : ""}
      </div>


    </div>
  );
}

export default VeliIndex;
