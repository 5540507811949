import axios from "axios";
import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { Link } from "react-router-dom";
import { iskonto } from "../iskonto";
function Index() {
  const [placeholder, setPlaceholder] = useState("Buraya Tıklayınız");
  const [ogrenci, setOgrenci] = useState(null);
  const [sepet, setSepet] = useState([]);
  const [satisb, setSatisB] = useState(0);
  const [buyable, setBuyable] = useState(true);
  const box = useRef()
  useEffect(() => {
    document.addEventListener("keydown", (e) => {
      if (e.code == "Space") {
        focus();
      }
    });
  }, []);

  function focus(e) {
    document.getElementById("search-input").focus();
  }

  function get(e) {
    e.preventDefault();
    setSatisB(0);

    setSepet([]);
    if (e.target[0].value != "") {
      setOgrenci([]);
      axios
        .get(`/getOgrenci/${e.target[0].value.split(" ").join("")}`)
        .then((res) => {
          console.log(res);
          if (res.data.length != 0) {
            setOgrenci(res.data);
          } else {
            setOgrenci(null);
          }

          e.target[0].value = "";
        })
        .catch((errr) => {
          setOgrenci(null);
          e.target[0].value = "";
        });
    }
  }

  function sepeteEkle(e, objs) {
    setSepet([...sepet, objs]);

    var toplam = iskonto(
      [...sepet, objs].reduce((a, b) => +a + +b.fiyat, 0),
      ogrenci[0].iskonto
    );

    if (toplam > ogrenci.harcama) {
      console.log("harcama");
      setBuyable(false);
    } else {
      setBuyable(true);
    }
  }

  function satis(e) {
    e.target.innerHTML = "<i class='fa-spin fa-spinner fa-solid'></i>";

    var toplam = iskonto(
      sepet.reduce((a, b) => +a + +b.fiyat, 0),
      ogrenci[0].iskonto
    );

    console.log(toplam);
    axios
      .post("/satis", {
        okul: localStorage.okul_id,
        ogrenci: ogrenci[0].ogrenci_id,
        toplam: toplam,
        sepet: sepet,
      })
      .then((res) => {
        if (res.data == "bakiye_yetersiz") {
          alert("Yetersiz bakiye");
        }
        if (res.data == "ok") {
          setSepet([]);
          setOgrenci(null);
          setSatisB(1);
          focus();
        }
      });
  }

  return (
    <div className="screen">
      <div className="page">
        <form onSubmit={(e) => get(e)} className="search  p-1 rounded">
          <div ref={box} className="p-2 search-border flex justify-between items-center">
            <i className="fa-solid fa-magnifying-glass text-white text-2xl"></i>
            <input
              tabIndex={0}
              type="password"
              id="search-input"
              placeholder={placeholder}
              onFocus={(e) => {
                setPlaceholder("Kartı Okutunuz")
                box.current.classList.add("parlat")
                box.current.style.borderColor = "#22c55e"
              }}
              onBlur={(e) => {
                setPlaceholder("Buraya tıklayınız veya boşluk tuşuna basınız")
                box.current.classList.remove("parlat")
                box.current.style.borderColor = "white"

              }}
              onKeyDown={(e) => {
                if (e.code == "Space") {
                  e.preventDefault();
                }
              }}
              className="text-lg flex-fill text-center bg-transparent"
            />
            <div></div>
          </div>
        </form>

        {satisb == 1 ? (
          <div className="flex-column bg-green-400 rounded-lg mt-4 py-5 flex justify-center items-center">
            <i className="fa-solid fa-check text-8xl text-green-800"></i>
            <div className="text-green-800 font-bold text-4xl">
              Satış Başarılı
            </div>
          </div>
        ) : (
          <></>
        )}

        {ogrenci == null ? (
          <div className=" mt-2 row g-2">
            <div className="col-6">
              <Link
                to="/urunler"
                className="px-2 py-4 h-full d-block hover:bg-slate-200 shadow-sm rounded bg-slate-50 kutu"
              >
                <div className="flex flex-column items-center justify-center">
                  <div>
                    <i className="fa-solid fa-utensils me-2 text-primary text-4xl cursor-pointer "></i>
                  </div>
                  <div className="text-center mt-2 px-3">
                    <div className="font-medium text-xl text-primary">
                      Ürünler
                    </div>
                    <div className="font-medium text-slate-400 lh-1  text-lg">
                      Ürünlerinizi ekleyin veya yönetin.
                    </div>
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-6">
              <Link
                to="/ogrenciler"
                className="px-2 py-4 h-full d-block hover:bg-slate-200 shadow-sm rounded bg-slate-50 kutu"
              >
                <div className="flex flex-column items-center justify-center">
                  <div>
                    <i className="fa-solid fa-user me-2 text-primary text-5xl cursor-pointer "></i>
                  </div>
                  <div className="text-center mt-2 px-3">
                    <div className="font-medium text-xl text-primary">
                      Öğrenciler
                    </div>
                    <div className="font-medium text-slate-400 lh-1  text-lg">
                      Öğrencileri veya grupları yönetin
                    </div>
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-6">
              <Link
                to="/stok?stok"
                className="px-2 h-full py-4 d-block hover:bg-slate-200 shadow-sm rounded bg-slate-50 kutu"
              >
                <div className="flex flex-column items-center justify-center">
                  <div>
                    <i className="fa-solid fa-box me-2 text-primary text-4xl cursor-pointer "></i>
                  </div>
                  <div className="text-center mt-2 px-3">
                    <div className="font-medium text-xl text-primary">
                      Stok Takibi
                    </div>
                    <div className="font-medium text-slate-400 lh-1  text-lg">
                      Stok girişi yapın veya stok hareketlerini inceleyin
                    </div>
                  </div>
                </div>
              </Link>
            </div>


            <div className="col-6">
              <Link
                to="/stok"
                className="px-2 h-full py-4 d-block hover:bg-slate-200 shadow-sm rounded bg-slate-50 kutu"
              >
                <div className="flex flex-column items-center justify-center">
                  <div>
                    <i className="fa-solid fa-receipt me-2 text-primary text-4xl cursor-pointer "></i>
                  </div>
                  <div className="text-center mt-2 px-3">
                    <div className="font-medium text-xl text-primary">
                      Siparişler
                    </div>
                    <div className="font-medium text-slate-400 lh-1  text-lg">
                      Verilen siparişleri inceleyin, iade yapın
                    </div>
                  </div>
                </div>
              </Link>
            </div>


           
          </div>
        ) : (
          ""
        )}

        {ogrenci != null ? (
          <div className="row mt-3 g-3">
            <div className="col-12">
              <div className="bg-white rounded p-2 shadow kutu">
                <div className="row">
                  <div className="col-4">
                    {ogrenci.length == 0 ? (
                      <div className="h-full-important w-full skeleton"></div>
                    ) : (
                      <img
                        className="img-fluid rounded-lg h-48 object-cover w-full"
                        src={ogrenci[0].resim}
                        alt=""
                      />
                    )}
                  </div>

                  <div className="col-8">
                    {ogrenci.length == 0 ? (
                      <div className="h-full p-4 flex flex-column justify-between">
                        <div className="skeleton"></div>
                        <div className="skeleton w-25 mt-5"></div>
                      </div>
                    ) : (
                      <div className="h-full p-4 flex flex-column justify-between">
                        <div>
                          <div className="lh-1 font-medium text-slate-400">
                            {ogrenci[0].grup_ismi}
                          </div>
                          <div className="font-bold text-3xl text-primary lh-1">
                            {ogrenci[0].isim}
                            <span className="text-zinc-700">
                              {" "}
                              - {ogrenci[0].numara}
                            </span>
                          </div>
                        </div>

                        <div>
                          <div className="text-primary fiyat-text lh-1 font-bold text-3xl">
                            {ogrenci[0].bakiye} ₺
                          </div>
                          <div className="font-medium text-xl ">
                            Güncel Bakiye
                          </div>
                          <div className="font-medium lh-1 ">
                            Kalan Günlük Bakiye:{" "}
                            <span className="text-primary fiyat-text">
                              {ogrenci.harcama} ₺
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-6">
              <div className="bg-white rounded p-2 shadow kutu">
                <div className="mb-2 text-green-700 font-medium text-xl">
                  <i className="fa-solid fa-check "></i> Alabilir
                </div>

                {ogrenci.yemekler &&
                ogrenci.yemekler.filter((el) => el.kabul == 1).length != 0 ? (
                  <div className="bg-slate-200 rounded p-1">
                    <i className="fa-solid fa-magnifying-glass text-sm "></i>
                    <input
                      type="text"
                      onChange={(e) => {
                        // Declare variables
                        var input, filter, table, tr, td, i, txtValue;
                        input = e.target;
                        filter = input.value.toUpperCase();
                        table = document.querySelector(".u-list");
                        tr = table.querySelectorAll(".u-list-name");

                        console.log(tr);

                        // Loop through all table rows, and hide those who don't match the search query
                        for (i = 0; i < tr.length; i++) {
                          td = tr[i];
                          if (td) {
                            txtValue = td.textContent || td.innerText;
                            if (txtValue.toUpperCase().indexOf(filter) > -1) {
                              tr[i].style.display = "";
                            } else {
                              tr[i].style.display = "none";
                            }
                          }
                        }
                      }}
                      placeholder="Ara"
                      className="pl-3 font-medium bg-transparent text-sm outline-none"
                    />
                  </div>
                ) : (
                  <div className="font-medium text-lg">
                    <i className="fa-solid fa-cancel"></i> Bu kişi hiç bir ürünü
                    alamaz.
                  </div>
                )}

                <div className="u-list">
                  {ogrenci.length != 0 ? (
                    ogrenci.yemekler &&
                    ogrenci.yemekler
                      .filter((el) => el.kabul == 1)
                      .map((val, i) => {
                        return (
                          <div
                            onClick={(e) =>
                              sepeteEkle(e, {
                                id: val.urun_id,
                                isim: val.urun_isim,
                                fiyat: val.urun_fiyat,
                              })
                            }
                            key={i}
                            className="u-list-name cursor-sepet p-1 rounded hover:bg-slate-100 active:bg-blue-200 font-medium text-lg flex items-center justify-between"
                          >
                            <div>{val.urun_isim}</div>{" "}
                            <div className="fiyat-text text-green-700">
                              {val.urun_fiyat} ₺
                            </div>
                          </div>
                        );
                      })
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>

            <div className="col-6">
              {sepet.length != 0 ? (
                <div className="bg-white rounded p-2 shadow kutu mb-2">
                  <div className="flex  mb-3 items-center justify-between">
                    <div className=" font-medium text-lg">
                      <i className="fa-solid fa-basket-shopping"></i> Sepet
                    </div>
                  </div>
                  {sepet.map((val, i) => {
                    return (
                      <div
                        className="flex font-medium justify-between items-center"
                        key={i}
                      >
                        <div>{val.isim}</div>
                        <div className="fiyat-text text-green-600">
                          {val.fiyat} ₺
                        </div>
                      </div>
                    );
                  })}

                  {ogrenci[0].rol == 1 ? (
                    <div className="mt-2 text-end font-medium">
                      %{ogrenci[0].iskonto} İskonto
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="flex font-bold mt-3 justify-between items-center">
                    <div>Toplam: </div>
                    <div className="text-green-700 fiyat-text">
                      {iskonto(
                        sepet.reduce((a, b) => +a + +b.fiyat, 0),
                        ogrenci[0].iskonto
                      )}
                      ₺
                    </div>
                  </div>

                  <button
                    disabled={!buyable}
                    onClick={(e) => satis(e)}
                    className="btn-disabled bg-green-600 py-1 px-2 w-100 text-center font-medium text-white  rounded"
                  >
                    {}
                    {buyable ? "Satış" : "Günlük Limit Aşımı"}
                  </button>

                  {!buyable ? (
                    <button
                      onClick={(e) => setSepet([])}
                      className="btn-disabled mt-2  bg-red-500 py-1 px-2 w-100 text-center font-medium text-white text-xs  rounded"
                    >
                      {}
                      Sepeti Boşalt
                    </button>
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <> </>
              )}
              <div className=" bg-white rounded p-2 shadow kutu">
                <div className="mb-2 text-red-500 font-medium text-xl">
                  <i className="fa-solid fa-cancel "></i> Alamaz
                </div>

                {ogrenci.yemekler &&
                ogrenci.yemekler.filter((el) => el.kabul == 0).length != 0 ? (
                  ""
                ) : (
                  <div className="font-medium text-lg">
                    <i className="fa-solid fa-check"></i> Bu kişi tüm ürünleri
                    alabilir.
                  </div>
                )}

                {ogrenci.length != 0 ? (
                  ogrenci.yemekler &&
                  ogrenci.yemekler
                    .filter((el) => el.kabul == 0)
                    .map((val, i) => {
                      return (
                        <div
                          key={i}
                          className="font-medium text-lg flex items-center justify-between"
                        >
                          <div>{val.urun_isim}</div>{" "}
                          <div className="fiyat-text text-green-700">
                            {val.urun_fiyat} ₺
                          </div>
                        </div>
                      );
                    })
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default Index;
