import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function Ogrenciler() {
  const [ogrenciler2, setOgrenciler] = useState(null);
  const [searchString, setSearchString] = useState("");
  const [mode, setMode] = useState(0);
  const [gruplar, setGruplar] = useState(null);
  const [tab, setTab] = useState("ogrenciler");

  let navigate = useNavigate();

  useEffect(() => {
    getOgrenci();
    getGrup();
  }, []);

  function getGrup() {
    setGruplar(null);
    axios.get(`/getGruplist/${localStorage.okul_id}`).then((res) => {
      console.log(res);

      setGruplar(res.data);
    });
  }

  function getOgrenci(search = "") {
    setOgrenciler(null);
    axios
      .get(`/getOgrenciler/${localStorage.okul_id}/?search=${search}`)
      .then((res) => {
        console.log(res);

        setOgrenciler(res.data);
      });
  }

  function addOgrenci(e) {
    e.preventDefault();

    e.target[6].innerHTML = "<i class='fa-spinner fa-spin fa-solid'></i>";

    axios
      .post(`/ogrenciEkle/${localStorage.okul_id}`, {
        isim: e.target[0].value,
        tc: e.target[1].value,
        telefon: e.target[2].value,
        numara: e.target[3].value,
        rfid: e.target[4].value,
        rol: e.target[5].value,
      })
      .then((res) => {
        if (res.data == "ok") {
          e.target[0].value = "";
          e.target[1].value = "";
          e.target[2].value = "";
          e.target[3].value = "";
          e.target[4].value = "";

          e.target[6].innerHTML = "Ekle";
        }
      });
  }

  function sil(e, id) {
    if (window.confirm("Bu ürünü silmek istediğiniz emin misiniz")) {
      e.target.innerHTML = "<i class='fa-solid fa-spin fa-spinner'></i>";
      axios.post(`/delete/urunler/urun_id/${id}`).then((res) => {
        console.log(res);
        if (res.data == "ok") {
          e.target.parentElement.parentElement.parentElement.remove();
        }
      });
    }
  }

  function addGrup(e) {
    e.preventDefault();

    e.target[2].innerHTML = "<i class='fa-solid fa-spin fa-spinner'></i>";

    axios
      .post("/addGrup", {
        to_okul: localStorage.okul_id,
        name: e.target[0].value,
        iskonto: e.target[1].value,
      })
      .then((res) => {
        getGrup();
        e.target[2].innerHTML = "Ekle";
        e.target[0].value = "";
        e.target[1].value = "";
      });
  }

  return (
    <div className="screen">
      <div className="page">
        <div class="mb-2">
          <div class="shadow-sm  flex items-center bg-white rounded-2 px-2">
            <div
              onClick={(e) => setTab("ogrenciler")}
              class={`cursor-pointer ${
                tab == "ogrenciler" ? "text-primary" : ""
              } hover:text-black  hover:bg-slate-100 py-2 flex-fill text-center font-medium`}
            >
              Öğrenciler
            </div>
            <div
              onClick={(e) => {
                setTab("gruplar");
                setMode(0);
              }}
              class={`cursor-pointer ${
                tab == "gruplar" ? "text-primary" : ""
              } border-x-2 hover:bg-slate-100 py-2 flex-fill text-center font-medium`}
            >
              Gruplar
            </div>
          </div>
        </div>

        {mode == 1 ? (
          <>
            <div className="bg-white rounded mb-2 shadow kutu p-2">
              <div className="font-medium">Öğrenci Ekle</div>
              <form onSubmit={(e) => addOgrenci(e)}>
                <div className="row g-2">
                  <div className="col-6">
                    <input
                      type="text"
                      required
                      className="bg-slate-100 rounded font-medium p-2 w-full"
                      placeholder="İsim Soyisim"
                    />
                  </div>
                  <div className="col-6">
                    <input
                      type="text"
                      required
                      className="bg-slate-100 rounded font-medium p-2 w-full"
                      placeholder="TC"
                    />
                  </div>
                  <div className="col-6">
                    <input
                      type="text"
                      className="bg-slate-100 rounded font-medium p-2 w-full"
                      placeholder="Telefon"
                    />
                  </div>
                  <div className="col-6">
                    <input
                      type="number"
                      className="bg-slate-100 rounded font-medium p-2 w-full"
                      placeholder="Okul Numarası"
                    />
                  </div>
                  <div className="col-6">
                    <input
                      onKeyDown={(e) => {
                        if (e.code == "Enter") {
                          e.preventDefault();
                        }
                      }}
                      type="text"
                      required
                      className="bg-slate-100 rounded font-medium p-2 w-full"
                      placeholder="Kart Numarası"
                    />
                  </div>

                  <div className="col-6">
                    <select className="bg-slate-100 rounded font-medium p-2 w-full">
                      {gruplar &&
                        gruplar.map((val, i) => {
                          return (
                            <option value={val.grup_id}>
                              {val.name} ({val.iskonto}% İskonto)
                            </option>
                          );
                        })}
                    </select>
                  </div>
                  <div className="col-6"></div>

                  <div className="col-3">
                    <button
                      type="submit"
                      disabled={!gruplar}
                      className="bg-primary btn-disabled py-2 w-full font-bold text-white h-full rounded"
                    >
                      Ekle
                    </button>
                  </div>

                  <div className="col-3">
                    <button
                      onClick={() => setMode(0)}
                      type="button"
                      className="py-2 hover:bg-slate-400 bg-slate-300 w-full font-bold h-full rounded"
                    >
                      Geri
                    </button>
                  </div>
                </div>
              </form>
            </div>{" "}
          </>
        ) : (
          <>
            {tab == "gruplar" ? (
              <div className="bg-white rounded mb-2 shadow kutu p-2">
                <div className="font-medium mt-1">Grup Ekle</div>
                <div className="row">
                  <form onSubmit={(e) => addGrup(e)}>
                    <div className="row g-2">
                      <div className="col-5">
                        <input
                          type="text"
                          required
                          className="bg-slate-100 rounded font-medium p-2 w-full"
                          placeholder="Grup İsmi"
                        />
                      </div>
                      <div className="col-4">
                        <input
                          type="number"
                          required
                          className="bg-slate-100 rounded font-medium p-2 w-full"
                          placeholder="Yüzdelik İskonto"
                        />
                      </div>

                      <div className="col-3">
                        <button
                          className="bg-primary rounded w-full p-2 font-medium button"
                          type="submit"
                        >
                          Ekle
                        </button>
                      </div>
                    </div>
                  </form>
                </div>

                <table className="table table-striped mt-2">
                  <thead>
                    <tr>
                      <td className="font-medium">Grup İsmi</td>
                      <td className="font-medium">İskonto Oranı</td>
                    </tr>
                  </thead>
                  <tbody>
                    {gruplar &&
                      gruplar.map((val, i) => {
                        return (
                          <tr key={i}>
                            <td>{val.name}</td>
                            <td>{val.iskonto}%</td>
                            {val.name != "Genel" ? (
                              <td>
                                 <button className="bg-green-500 py-1 px-2 rounded mr-2">
                                  <i className="text-white fa-solid fa-pen"></i>
                                </button>
                                <button
                                  onClick={(e) => {
                                    e.persist();
                                    if (
                                      window.confirm(
                                        "Grubu silmek istediğinize emin misiniz"
                                      )
                                    ) {
                                      e.currentTarget.innerHTML =
                                        "<i class='fa-solid fa-spin fa-spinner'></i>";
                                      axios
                                        .post(
                                          `/grupSil/${localStorage.okul_id}/${val.grup_id}/`
                                        )
                                        .then((res) => {
                                          console.log(res);
                                          console.log(e);
                                          if (res.data == "ok") {
                                            getGrup();
                                          }
                                        });
                                    }
                                  }}
                                  className="bg-red-500 py-1 px-2 rounded"
                                >
                                  <i className="text-white fa-solid fa-trash"></i>
                                </button>
                              </td>
                            ) : (
                              <td>
                                <button className="bg-green-500 py-1 px-2 rounded">
                                  <i className="text-white fa-solid fa-pen"></i>
                                </button>
                              </td>
                            )}
                          </tr>
                        );
                      })}
                    {gruplar == null ? (
                      <>
                        <tr>
                          <td>Yükleniyor</td>
                        </tr>
                      </>
                    ) : (
                      <></>
                    )}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="bg-white rounded shadow kutu p-2">
                <div className="row g-2 ">
                  <div className="col-8">
                    <input
                      onKeyDown={(e) => {
                        if (e.code == "Enter") {
                          getOgrenci(searchString);
                        }
                      }}
                      value={searchString}
                      onChange={(e) => setSearchString(e.target.value)}
                      type="text"
                      className="bg-slate-100 rounded font-medium p-2 w-full"
                      placeholder="TC,Numara, Kart No, İsim ara"
                    />
                  </div>
                  <div className="col-1">
                    <button
                      onClick={(e) => {
                        getOgrenci(searchString);
                      }}
                      className="h-full bg-primary w-100 font-medium text-white rounded"
                    >
                      <i className="fa-solid fa-magnifying-glass"></i>
                    </button>
                  </div>
                  <div className="col-3">
                    <button
                      onClick={() => setMode(1)}
                      className="h-full bg-zinc-800 text-white  w-100 font-bold  rounded"
                    >
                      <i className="fa-solid fa-plus"></i> Ekle
                    </button>
                  </div>
                </div>

                {ogrenciler2 && ogrenciler2.length == 0 ? (
                  <div className="text-center font-bold text-primary mt-3">
                    Sonuç bulunamadı
                  </div>
                ) : (
                  ""
                )}

                <div className="row g-2 mt-1">
                  {ogrenciler2 &&
                    ogrenciler2.map((val, i) => {
                      return (
                        <div
                          key={i}
                          className="col-4"
                          onClick={(e) =>
                            navigate(`/ogrenci/${val.ogrenci_id}`)
                          }
                        >
                          <div className="rounded position-relative cursor-pointer hover:bg-slate-200 bg-slate-50 p-2">
                            <div className="flex justify-center">
                              <img
                                loading="lazy"
                                src={
                                  val.resim != ""
                                    ? val.resim
                                    : axios.defaults.base +
                                      "assets/img/profil.jpg"
                                }
                                className="w-24 h-24 object-cover rounded-full"
                                alt=""
                              />
                            </div>
                            <div className="font-bold mt-2 lh-1 text-slate-400 text-sm text-center text-primary">
                              {val.numara}
                            </div>

                            <div className="font-medium  text-center">
                              {val.isim}
                            </div>
                            <div className="font-medium lh-1 text-slate-400 text-sm text-center">
                              {val.tc}
                            </div>
                          </div>
                        </div>
                      );
                    })}

                  {ogrenciler2 == null ? (
                    <>
                      <div className="col-4">
                        <div className="rounded position-relative cursor-pointer  bg-slate-50 p-2">
                          <div className="flex justify-center mb-1">
                            <div className="skeleton w-24 h-24-important  rounded-full "></div>
                          </div>
                          <div className="w-25 mx-auto d-block mb-1 text-center skeleton br-8"></div>
                          <div className="w-75 mx-auto d-block mb-1 text-center skeleton br-8"></div>
                          <div className="w-75 mx-auto d-block mb-1 text-center skeleton br-8"></div>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="rounded position-relative cursor-pointer  bg-slate-50 p-2">
                          <div className="flex justify-center mb-1">
                            <div className="skeleton w-24 h-24-important  rounded-full "></div>
                          </div>
                          <div className="w-25 mx-auto d-block mb-1 text-center skeleton br-8"></div>
                          <div className="w-75 mx-auto d-block mb-1 text-center skeleton br-8"></div>
                          <div className="w-75 mx-auto d-block mb-1 text-center skeleton br-8"></div>
                        </div>
                      </div>

                      <div className="col-4">
                        <div className="rounded position-relative cursor-pointer  bg-slate-50 p-2">
                          <div className="flex justify-center mb-1">
                            <div className="skeleton w-24 h-24-important  rounded-full "></div>
                          </div>
                          <div className="w-25 mx-auto d-block mb-1 text-center skeleton br-8"></div>
                          <div className="w-75 mx-auto d-block mb-1 text-center skeleton br-8"></div>
                          <div className="w-75 mx-auto d-block mb-1 text-center skeleton br-8"></div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}
