import React, { useEffect } from 'react'

function Cikis() {
    useEffect(()=>{
        localStorage.removeItem("okul_id")
        localStorage.removeItem("veli_id")
        window.location = "/"
    },[])
  return (
    <div></div>
  )
}

export default Cikis