export function iskonto(toplam,iskonto){
    return (parseFloat(toplam) - (Number(iskonto) * (toplam / 100)).toFixed(2)).toFixed(2)
}

export const aylar = [
    "Ocak",
    "Şubat",
    "Mart",
    "Nisan",
    "Mayıs",
    "Haziran",
    "Temmuz",
    "Ağustos",
    "Eylül",
    "Ekim",
    "Kasım",
    "Aralık",
]