import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Routes,
} from "react-router-dom";
import Index from "./pages/Index";
import "./assets/css/style.css";
import Urunler from "./pages/Urunler";
import AnaSayfa from "./pages/AnaSayfa";
import VeliIndex from "./pages/VeliIndex";
import Cikis from "./components/Cikis";
import Ogrenciler from "./pages/Ogrenciler";
import Stok from "./pages/Stok";
import UrunDetay from "./pages/UrunDetay";
import ReactTooltip from "react-tooltip"
import OgrenciDetay from "./pages/OgrenciDetay";

function App() {
  return (
    <Router>
      <div className="h-screen main">
        {localStorage.okul_id ? (
          <nav className="nav flex items-center position-fixed  bg-white p-2 rounded shadow-sm">
            <div className="text-primary text-lg font-bold me-3">
              Mithatpaşa Lisesi
            </div>


            <div className="ms-auto">
              <Link to="/">
                <ReactTooltip effect="solid"/>
                <i data-tip="Kart Tara"  className="fa-regular me-2 fa-address-card text-gray-500  h-text-primary text-3xl cursor-pointer "></i>
              </Link>

              <Link to="/urunler">
                <ReactTooltip effect="solid"/>
                <i data-tip="Ürünler" className="fa-solid fa-utensils me-2 text-gray-500 h-text-primary text-3xl cursor-pointer "></i>
              </Link>

              <Link to="/stok">
               <ReactTooltip effect="solid"/>
                <i data-tip="Stok & Siparişler" className="fa-solid fa-box me-2 text-gray-500 h-text-primary text-3xl cursor-pointer "></i>
              </Link>
              
              <Link to="/ogrenciler">
                <ReactTooltip effect="solid"/>
                <i data-tip="Öğrenciler & Gruplar" className="fa-solid fa-user text-gray-500 h-text-primary text-3xl cursor-pointer "></i>
              </Link>

              <Link to="/cikis" onClick={e=>{
                if(!window.confirm("Çıkış yapmak istediğinize emin misiniz")){
                  e.preventDefault()
                }
              }}>
                <ReactTooltip effect="solid"/>
                <i data-tip="Çıkış Yap" className="fa-solid ms-2 fa-right-from-bracket text-gray-500 h-text-primary text-3xl cursor-pointer "></i>
              </Link>
            </div>
          </nav>
        ) : (
          <></>
        )}
        {localStorage.okul_id ? (
          <Routes>
            <Route path="/" element={<Index />} />
            <Route path="/urunler" element={<Urunler />} />
            <Route path="/urun/:id" element={<UrunDetay />} />
            <Route path="/ogrenciler" element={<Ogrenciler />} />
            <Route path="/ogrenci/:id" element={<OgrenciDetay/>} />
            <Route path="/stok" element={<Stok />} />
            <Route path="/cikis" element={<Cikis />} />
            
          </Routes>
        ) : (
          <></>
        )}

        {localStorage.veli_id ? (
          <Routes>
            <Route path="/" element={<VeliIndex />} />
            <Route path="/cikis" element={<Cikis />} />

          </Routes>
        ) : (
          <></>
        )}

        {
          !localStorage.veli_id && !localStorage.okul_id  ? <Routes>
          <Route path="/" element={<AnaSayfa />} />
        </Routes> : <></>
        }
      </div>
    </Router>
  );
}

export default App;
