import axios from "axios";
import React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

function OgrenciDetay() {
  let params = useParams();
  const [data, setData] = useState(null);
  const [gruplar, setGruplar] = useState(null);

  useEffect(() => {
    axios.get(`/getOgrenciID/${params.id}`).then((res) => {
      console.log(res);
      setData(res.data);
    });

    getGrup();
  }, []);

  function getGrup() {
    setGruplar(null);
    axios.get(`/getGruplist/${localStorage.okul_id}`).then((res) => {
      console.log(res);

      setGruplar(res.data);
    });
  }

  function edit(e) {
    e.preventDefault();
    e.target[7].innerHTML = "<i class='fa-solid fa-spin fa-spinner'></i>";
    e.target[7].setAttribute("disabled", "true");
    console.log(e);
    axios
      .post(`/editOgrenci/${params.id}`, {
        isim: e.target[0].value,
        tc: e.target[1].value,
        telefon: e.target[2].value,
        mail: e.target[3].value,
        rfid: e.target[4].value,
        numara: e.target[5].value,
        grup: e.target[6].value,
      })
      .then((res) => {
        console.log(res);
        if (res.data == "ok") {
          e.target[7].removeAttribute("disabled");
          e.target[7].innerHTML = "<i class='fa-solid fa-check'></i>";
        }
      });
  }

  return (
    <div className="screen">
      <div className="page">
        <div className="row g-2">
          <div className="col-6">
            <div className="bg-white rounded shadow-sm p-2 kutu">
              {data != null ? (
                <>
                  <div className="flex justify-center">
                    <img
                      loading="lazy"
                      src={
                        data.resim != ""
                          ? data.resim
                          : axios.defaults.base + "assets/img/profil.jpg"
                      }
                      className="w-36 h-36 object-cover rounded-full"
                      alt=""
                    />
                  </div>
                  <div className="font-medium text-center text-lg">{data.isim}</div>
                  <div className="font-medium text-center text-lg  fiyat-text text-green-600">{data.bakiye} ₺</div>
                  <iframe id="ifr" src={`${axios.defaults.baseURL+"resim/"+params.id+"/?type=ogrenci"}`} height="50"></iframe>

                  {" "}
                </>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="col-6">
            <div className="bg-white rounded shadow-sm p-2 kutu">
              {data != null ? (
                <form onSubmit={(e) => edit(e)}>
                  <div className="row g-2">
                    <div className="col-12">
                      <div className="font-medium text-sm">İsim Soyisim</div>
                      <div>
                        <input
                          type="text"
                          defaultValue={data.isim}
                          className="font-medium px-2 py-1 bg-slate-100 rounded w-100"
                        />
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="font-medium text-sm">TC</div>
                      <div>
                        <input
                          type="text"
                          defaultValue={data.tc}
                          className="font-medium px-2 py-1 bg-slate-100 rounded w-100"
                        />
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="font-medium text-sm">Telefon</div>
                      <div>
                        <input
                          type="text"
                          defaultValue={data.telefon}
                          className="font-medium px-2 py-1 bg-slate-100 rounded w-100"
                        />
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="font-medium text-sm">Mail</div>
                      <div>
                        <input
                          type="text"
                          defaultValue={data.mail}
                          className="font-medium px-2 py-1 bg-slate-100 rounded w-100"
                        />
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="font-medium text-sm">Kart Numarası</div>
                      <div>
                        <input
                          type="text"
                          defaultValue={data.rfid}
                          className="font-medium px-2 py-1 bg-slate-100 rounded w-100"
                        />
                      </div>
                    </div>

                    <div className="col-6">
                      <div className="font-medium text-sm">Okul No</div>
                      <div>
                        <input
                          type="text"
                          defaultValue={data.numara}
                          className="font-medium px-2 py-1 bg-slate-100 rounded w-100"
                        />
                      </div>
                    </div>

                    <div className="col-6">
                      <div className="font-medium text-sm">Grup</div>
                      <div>
                        <select className="font-medium px-2 py-1 bg-slate-100 rounded w-100">
                          {gruplar &&
                            gruplar.map((val, i) => {
                              return (
                                <option
                                  selected={
                                    data.grup == val.grup_id ? true : false
                                  }
                                  value={val.grup_id}
                                  key={val.grup_id}
                                >{`${val.name} (%${val.iskonto})`}</option>
                              );
                            })}
                        </select>
                      </div>
                    </div>

                    <div className="col-6"></div>
                    <div className="col-6">
                      <button
                        type="submit"
                        className="btn-disabled hover:bg-green-600 bg-green-500 text-white font-medium rounded p-1 w-100"
                      >
                        Kaydet
                      </button>
                    </div>
                  </div>
                </form>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OgrenciDetay;
