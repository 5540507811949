import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function Urunler() {
  const [urunler, setUrunler] = useState(null);
  const [img, setImg] = useState("");
  const [type, setType] = useState("");
  const [tab, setTab] = useState("urunler")
  useEffect(() => {
    axios.get(`/urunler/${localStorage.okul_id}`).then((res) => {
      setUrunler(res.data);
    });
  }, []);
  function urunEkle(e) {
    e.preventDefault();
    console.log(e);
    e.target[6].innerHTML = "<i class='fa-spin fa-spinner fa-solid'></i>";
    axios
      .post("/urunEkle/", {
        to_okul: localStorage.okul_id,
        urun_isim: e.target[0].value,
        urun_fiyat: e.target[1].value,
        kalori: e.target[2].value,
        barkod: e.target[3].value,
        stok: e.target[4].value,
        resim: img,
        type: type,
      })
      .then((res) => {
        console.log(res);
        e.target[6].innerHTML = "Ekle";
        e.target[4].value = "";
        e.target[3].value = "";
        e.target[1].value = "";
        e.target[2].value = "";
        e.target[0].value = "";

        axios.get(`/urunler/${localStorage.okul_id}`).then((res) => {
          setUrunler(res.data);
        });
      });
  }

  function sil(e,id){
    if(window.confirm("Bu ürünü silmek istediğiniz emin misiniz")){
      e.target.innerHTML = "<i class='fa-solid fa-spin fa-spinner'></i>"
      axios.post(`/delete/urunler/urun_id/${id}`).then(res=>{
        console.log(res)
        if(res.data == "ok"){
          e.target.parentElement.parentElement.parentElement.remove()
        }
      })
    }
  }
  return (
    <div className="screen">
      <div className="page">
           <div class="mb-2">
            <div class="shadow-sm  flex items-center bg-white rounded-2 px-2">
              <div onClick={e=>setTab("urunler")} class={`cursor-pointer ${tab == "urunler" ? "text-primary" : ""} hover:text-black  hover:bg-slate-100 py-2 flex-fill text-center font-medium`}>Ürünler</div>
              <div onClick={e=>{
                setTab("ekle");
              
              }} class={`cursor-pointer ${tab == "ekle" ? "text-primary" : ""} border-x-2 hover:bg-slate-100 py-2 flex-fill text-center font-medium`}><i className="fa-solid fa-plus"></i> Ekle</div>
            </div>
          </div>

        <div className="row g-2">

          {tab == "ekle" ? 
          <div className="col-6">
            <div className="bg-white rounded p-2 shadow kutu">
              <div className="font-bold mb-3">
                <i className="fa-solid fa-plus"></i> Ürün Ekle
              </div>

              <form onSubmit={(e) => urunEkle(e)}>
                <div className="row g-2">
                  <div className="col-12">
                    <input
                      required
                      type="text"
                      className="bg-slate-100 font-medium w-100 d-block p-2 rounded border"
                      placeholder="Ürün İsmi"
                    />
                  </div>

                  <div className="col-6">
                    <input
                      min={0}
                      type="number"
                      className="bg-slate-100 font-medium w-100 d-block p-2 rounded border"
                      placeholder="Fiyat"
                    />
                  </div>

                  <div className="col-6">
                    <input
                      min={0}
                      type="number"
                      className="bg-slate-100 font-medium w-100 d-block p-2 rounded border"
                      placeholder="Kalori"
                    />
                  </div>

                  <div className="col-6">
                    <input
                      type="text"
                      className="bg-slate-100 font-medium w-100 d-block p-2 rounded border"
                      placeholder="Barkod"
                    />
                  </div>
                  <div className="col-6">
                    <input
                      type="number"
                      className="bg-slate-100 font-medium w-100 d-block p-2 rounded border"
                      placeholder="Stok Sayısı"
                    />
                  </div>

                  <div className="col-12">
                    <input
                      accept="image/jpeg"
                      onChange={(e) => {
                        let files = e.target.files;
                        let fileReader = new FileReader();
                        fileReader.readAsDataURL(files[0]);

                        console.log(files[0]);

                        fileReader.onload = (event) => {
                          setType(files[0].type);
                          setImg(event.target.result);
                        };
                      }}
                      type="file"
                      className="d-none"
                    />
                  </div>

                  <div className="col-6">
                    <button type="submit" className="button p-2 w-full h-full">
                      Ekle
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
 :        
          <div className="col-12 mt-4">
            <div className="row g-2">
              {urunler &&
                urunler.map((val, i) => {
                  return (
                    <div key={i} className="col-4">
                      <div className=" h-full kantin-urun bg-white rounded shadow  position-relative">
                        <div className="show-urun-edit position-absolute w-full h-full bg-zinc-700/50 rounded flex items-center justify-center flex-column">
                          <Link
                            to={`/urun/${val.urun_id}`}
                            className="bg-green-400 hover:bg-green-500 px-3 w-28  py-1 rounded font-medium"
                          >
                            <i className="fa-solid fa-pen"></i> Düzenle
                          </Link>
                          <button onClick={e=>sil(e,val.urun_id)} className="bg-red-400 hover:bg-red-500 px-3 w-28 text-white mt-2  py-1 rounded font-medium">
                            <i className="fa-solid fa-trash"></i> Sil
                          </button>
                        </div>

                        <img
                          loading="lazy"
                          className="w-full object-contain rounded h-36"
                          src={
                            val.urun_resim != ""
                              ? val.urun_resim
                              : axios.defaults.base + "assets/img/yemek.jpg"
                          }
                          alt=""
                        />
                        <div className="p-2">
                          <div className="text-xs font-bold text-slate-400 lh-1">
                            {val.barkod}
                          </div>
                          <div className="font-medium text-lg lh-1">
                            {val.urun_isim}
                          </div>
                          <div className="flex items-center justify-between">
                            <div className="fiyat-text font-medium text-end text-green-600 lh-1 text-lg">
                              {val.urun_fiyat} ₺
                            </div>
                            <div className="font-medium text-sm text-italic">
                              {val.kalori} kcal
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}

                {
                  urunler == null ? <>
                  <div className="col-4">
                    <div className="h-full kantin-urun bg-white rounded shadow ">
                    <div className="w-full h-24-important skeleton rounded-top"></div>

                      <div className="p-2">
                      <div className="skeleton mt-1 w-25 d-block rounded"></div>
                      <div className="skeleton mt-1 w-50 d-block rounded"></div>
                      </div>
                    </div>
                  </div>

                  <div className="col-4">
                    <div className="h-full kantin-urun bg-white rounded shadow ">
                    <div className="w-full h-24-important skeleton rounded-top"></div>

                      <div className="p-2">
                      <div className="skeleton mt-1 w-25 d-block rounded"></div>
                      <div className="skeleton mt-1 w-50 d-block rounded"></div>
                      </div>
                    </div>
                  </div>

                  <div className="col-4">
                    <div className="h-full kantin-urun bg-white rounded shadow ">
                    <div className="w-full h-24-important skeleton rounded-top"></div>

                      <div className="p-2">
                      <div className="skeleton mt-1 w-25 d-block rounded"></div>
                      <div className="skeleton mt-1 w-50 d-block rounded"></div>
                      </div>
                    </div>
                  </div>

                  <div className="col-4">
                    <div className="h-full kantin-urun bg-white rounded shadow ">
                    <div className="w-full h-24-important skeleton rounded-top"></div>

                      <div className="p-2">
                      <div className="skeleton mt-1 w-25 d-block rounded"></div>
                      <div className="skeleton mt-1 w-50 d-block rounded"></div>
                      </div>
                    </div>
                  </div>

                  <div className="col-4">
                    <div className="h-full kantin-urun bg-white rounded shadow ">
                    <div className="w-full h-24-important skeleton rounded-top"></div>

                      <div className="p-2">
                      <div className="skeleton mt-1 w-25 d-block rounded"></div>
                      <div className="skeleton mt-1 w-50 d-block rounded"></div>
                      </div>
                    </div>
                  </div>

                  <div className="col-4">
                    <div className="h-full kantin-urun bg-white rounded shadow ">
                    <div className="w-full h-24-important skeleton rounded-top"></div>

                      <div className="p-2">
                      <div className="skeleton mt-1 w-25 d-block rounded"></div>
                      <div className="skeleton mt-1 w-50 d-block rounded"></div>
                      </div>
                    </div>
                  </div>
                  </> : ""
                }
            </div>
          </div>
}
        </div>
      </div>
    </div>
  );
}

export default Urunler;
