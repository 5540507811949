import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { aylar } from "../iskonto";

function Stok() {
  let navigate = useNavigate();

  console.log(window.location)

  const start = useRef()
  const bitis = useRef()
  const aralikTitle = useRef()

  const [stok, setStok] = useState(null);
  const [urunler, setUrunler] = useState(null);
  const [tab, setTab] = useState( window.location.search == "?stok" ? "stok" : "siparisler");
  const [siparisler, setSiparisler] = useState(null);

  useEffect(() => {
    axios.get(`/stok_hareketleri/${localStorage.okul_id}`).then((res) => {
      setStok(res.data);
    });

    axios.get(`/okulSiparisler/${localStorage.okul_id}`).then((res) => {
      setSiparisler(res.data);
    });

    axios.get(`/urunler/${localStorage.okul_id}`).then((res) => {
      setUrunler(res.data);
    });
  }, []);

  function tarihSearch(e){
    e.preventDefault()
    console.log({
        start: start.current.value,
        bitis: bitis.current.value,
    })

    if(start.current.value == ""){
        alert("Başlangıç tarihi giriniz")
        return
    }

    if(bitis.current.value == ""){
        alert("Bitiş tarihi giriniz")
        return
    }

    aralikTitle.current.innerText = "Belirtilen Tarih"
    setSiparisler(null)
    axios.get(`/okulSiparisler/${localStorage.okul_id}?start=${start.current.value}&bitis=${bitis.current.value}`).then((res) => {
        console.log(res)
      
        setSiparisler(res.data);
      });

      
  }

  function stokGir(e) {
    e.preventDefault();
    e.target[2].innerHTML = "<i class='fa-solid fa-spinner fa-spin'></i>";
    axios
      .post("/stok", {
        urun_id: e.target[0].value,
        qty: e.target[1].value,
        type: "giris",
        to_okul: localStorage.okul_id,
      })
      .then((res) => {
        console.log(res);

        axios.get(`/stok_hareketleri/${localStorage.okul_id}`).then((res2) => {
          setStok(res2.data);
          e.target[0].value = urunler[0]["urun_id"];
          e.target[1].innerHTML = "";
          e.target[2].innerHTML = "Stok Gir";
        });
      });
  }

  return (
    <div className="screen">
      <div className="page">

        <div className="mb-2 shadow-sm  flex items-center bg-white rounded-2 px-2">
          <div
            onClick={(e) => setTab("siparisler")}
            class={`cursor-pointer ${
              tab == "siparisler" ? "text-primary" : ""
            } hover:text-black  hover:bg-slate-100 py-2 flex-fill text-center font-medium`}
          >
            Siparişler
          </div>
          <div
            onClick={(e) => {
              setTab("stok");
            }}
            class={`cursor-pointer ${
              tab == "stok" ? "text-primary" : ""
            } border-x-2 hover:bg-slate-100 py-2 flex-fill text-center font-medium`}
          >
            Stok
          </div>
        </div>

       

        {tab == "stok" ? (
          <>
        

            <div className="bg-white rounded p-2 shadow kutu">
              <form onSubmit={(e) => stokGir(e)}>
                <div className="font-medium text-lg">Stok Giriş</div>

                <div className="row g-2">
                  <div className="col-4">
                    <select
                      required
                      name=""
                      className="w-full bg-slate-100 rounded p-2"
                    >
                      {urunler &&
                        urunler.map((val, i) => {
                          return (
                            <option value={val.urun_id} key={i}>
                              {val.urun_isim}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                  <div className="col-4">
                    <input
                      required
                      type="number"
                      placeholder="Adet"
                      className="bg-slate-100 rounded font-medium p-2 w-full"
                    />
                  </div>

                  <div className="col-4">
                    <button className="w-full p-2 bg-green-500 rounded font-medium">
                      Stok Gir
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className="mt-2 bg-white rounded p-2 shadow kutu">
              <div className="text-lg font-medium">Stok Hareketleri</div>

              <div className="max-scroll">
                {stok ? (
                  <table className="table">
                    <thead>
                      <tr>
                        <td className="font-bold">Ürün</td>
                        <td className="font-bold">Adet</td>
                        <td className="font-bold">Olay</td>
                        <td className="font-bold">Tarih</td>
                      </tr>
                    </thead>
                    <tbody>
                      {stok.map((val, i) => {
                        return (
                          <tr
                            onClick={(e) => navigate("/urun/" + val.urun_id)}
                            key={i}
                            className="cursor-pointer hover:bg-slate-100"
                          >
                            <td className="font-medium border-0">
                              {val.urun_isim}
                            </td>
                            <td className="font-medium border-0">{val.qty}</td>
                            <td className="font-medium border-0">
                              {val.type == "giris" ? (
                                <span className="rounded-full text-xs bg-green-400/50 px-2 py-1">
                                  Giriş
                                </span>
                              ) : (
                                ""
                              )}

                              {val.type == "cikis" ? (
                                <span className="rounded-full text-xs bg-red-400/50 px-2 py-1">
                                  Çıkış
                                </span>
                              ) : (
                                ""
                              )}

                              {val.type == "iade_giris" ? (
                                <span className="rounded-full text-xs bg-amber-400/50 px-2 py-1">
                                  İade Giriş
                                </span>
                              ) : (
                                ""
                              )}
                            </td>
                            <td className="border-0">
                              {new Date().toDateString() ==
                              new Date(val.tarih).toDateString()
                                ? `Bugün , ${new Date(
                                    val.tarih
                                  ).getHours()}:${new Date(
                                    val.tarih
                                  ).getMinutes()}`
                                : `${new Date(val.tarih).getDay()} ${
                                    aylar[new Date().getMonth()]
                                  }, ${new Date(
                                    val.tarih
                                  ).getHours()}:${new Date(
                                    val.tarih
                                  ).getMinutes()}`}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                ) : (
                  ""
                )}
              </div>
            </div>
          </>
        ) : (
          ""
        )}

        {tab == "siparisler" ? (
          <>
             <div className="mb-2 shadow-sm  flex items-center justify-between bg-white rounded-2 px-2">
             <div className="font-medium" ref={aralikTitle}>Tüm zamanlar</div>
             <form onSubmit={e=>tarihSearch(e)} >
                <input ref={start} type="date"  className="bg-slate-100 p-2 border-l-5"/>
                <input  ref={bitis} type="date" className="bg-slate-100 p-2 "/>
                <button type="submit" className="bg-primary text-xs p-2 rounded-full "><i className="fa-solid fa-magnifying-glass "></i> </button>
             </form>
        </div>

            <div className="mt-2 bg-white rounded p-2 shadow kutu">
              <div className="text-lg font-medium">Siparişler</div>

              <div className="max-scroll">
                {siparisler ? (
                  <table className="table">
                    <thead>
                      <tr>
                        <td className="font-bold">Ürün</td>
                        <td className="font-bold">Fiyat</td>
                        <td className="font-bold">Kişi</td>
                        <td className="font-bold">Tarih</td>
                        <td className="font-bold">İşlem</td>
                      </tr>
                    </thead>
                    <tbody>
                      {siparisler.map((val, i) => {
                        return (
                          <tr
                            key={i}
                            className="cursor-pointer hover:bg-slate-100"
                          >
                            <td className="font-medium border-0">{val.urun}</td>
                            <td className="fiyat-text text-green-500 font-medium border-0">
                              {val.fiyat} ₺
                            </td>
                            <td className="border-0 text-sm">{val.isim}</td>
                            <td className="border-0 text-sm">
                              {new Date().toDateString() ==
                              new Date(val.tarih).toDateString()
                                ? `Bugün , ${new Date(
                                    val.tarih
                                  ).getHours()}:${new Date(
                                    val.tarih
                                  ).getMinutes()}`
                                : `${new Date(val.tarih).getDay()} ${
                                    aylar[new Date().getMonth()]
                                  }, ${new Date(
                                    val.tarih
                                  ).getHours()}:${new Date(
                                    val.tarih
                                  ).getMinutes()}`}
                            </td>

                            <td className="border-0">
                              <button
                                className="hover:bg-amber-500 bg-amber-400 rounded text-xs font-medium py-1 px-2"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  if (
                                    window.confirm(
                                      "Bu ürün stoğunuza tekrar eklenip, ücreti satın alan kişinin bakiyesine geri yüklenecektir. İade etmek istediğinize emin misiniz?"
                                    )
                                  ) {
                                    e.target.innerHTML = "<i class='fa-solid fa-spinner fa-spin'></i>"
                                    axios.post(`/iade/${val.siparis_id}`).then(res=>{
                                        console.log(res)
                                        if(res.data == "ok"){
                                            alert("İade işlemi başarılı")
                                            e.target.parentElement.parentElement.remove()
                                        }
                                    })
                                  }
                                }}
                              >
                                İade
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                ) : (
                  ""
                )}

                {
                    siparisler && siparisler.length == 0 ? <div className="p-2 bg-amber-500/50 font-medium rounded">
                        Veri bulunamadı
                    </div> : ""
                }
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default Stok;
